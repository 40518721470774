import React from 'react';
import { VisuallyHidden, Stack, Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import {
    useOAuth2Login,
    coreSharedMessages,
    LoginLogo,
    LoginForm,
    LinkIntl,
    LanguageContext,
    emptyServerSideProps,
    withMaintenance,
    useMaintenance,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { LayoutBoundary } from 'components/Layouts';
import { ThemeContext } from 'themes';
import siteConfig from 'lib/site/config.json';
import OAuthRedirection from 'components/login/OAuthRedirection';
import { loginFormWidgets, withAuthRedirect } from 'lib/auth';
import TermsFooter from 'lib/shared/components/TermsFooter';
import { Site } from 'lib/site';
import { FullCenteredScreenLayout } from 'core/components/Layouts';
import Alert from 'design-system/components/Alert';

const lostCredentialsLinks = [
    {
        href: '/forgot-username',
        message: coreSharedMessages.lostUsername,
    },
    {
        href: '/forgot-password',
        message: coreSharedMessages.lostPassword,
    },
];

function LoginPage() {
    const { client, client_id, theme } = siteConfig;
    const registerButtonColorScheme = (theme as Site.Config['theme']).components
        ?.register?.button?.colorScheme;
    const { locale } = React.useContext(LanguageContext);
    const { data: maintenanceData } = useMaintenance(locale);
    const { isMobile } = useWindowBreakpoints();
    const { setVariant } = React.useContext(ThemeContext);
    const {
        features: { oauth2login },
    } = siteConfig;
    useOAuth2Login('redirect');

    React.useEffect(() => {
        setVariant(siteConfig.theme.variant);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _renderAdditionalAction = () => {
        return (
            <LinkIntl href="/register" passHref>
                <Button
                    size={isMobile ? 'lg' : 'md'}
                    w="full"
                    {...(!!registerButtonColorScheme
                        ? {
                              colorScheme: registerButtonColorScheme,
                          }
                        : {
                              backgroundColor: 'primary.ultralight',
                              color: 'primary.dark',
                          })}>
                    <FormattedMessage
                        id="components.login.register"
                        defaultMessage="Première connexion"
                    />
                </Button>
            </LinkIntl>
        );
    };

    return (
        <FullCenteredScreenLayout
            title={coreSharedMessages.loginTitle}
            hideBg={oauth2login}
            Footer={() => (
                <TermsFooter textColor={!isMobile ? 'white' : 'primary.main'} />
            )}>
            <VisuallyHidden>
                <h1>
                    <FormattedMessage {...coreSharedMessages.loginTitle} />
                </h1>
            </VisuallyHidden>

            {oauth2login ? (
                <OAuthRedirection />
            ) : (
                <Stack p={isMobile ? 0 : 6}>
                    <LoginLogo logo={client.logo} />

                    {/* @TODO Temp Octobre Rose */}
                    {client_id === 'caa_caa' && (
                        <Alert
                            showIcon={false}
                            color="primary"
                            showOctobreRoseLogo
                            stackProps={{ alignItems: 'center' }}
                            description={
                                <FormattedMessage
                                    id="login.octobre-rose-description"
                                    defaultMessage="À l'occasion d’<b>Octobre Rose</b>, nous avons habillé CA Ma Santé de rose pour soutenir la <b>lutte contre le cancer du sein</b>"
                                />
                            }
                        />
                    )}
                    <LoginForm
                        config={siteConfig}
                        LayoutBoundary={LayoutBoundary}
                        widgets={loginFormWidgets}
                        AdditionalAction={() => _renderAdditionalAction()}
                        footerLinks={lostCredentialsLinks}
                        maintenance={maintenanceData}
                    />
                </Stack>
            )}
        </FullCenteredScreenLayout>
    );
}

export default withMaintenance(LoginPage, TermsFooter);

export const getServerSideProps = withAuthRedirect(emptyServerSideProps);
